.hero-tampa {
  background: url('../images/st-pete/Spa-Invitational-Hero-St-Pete.jpg') center right/cover no-repeat;
  background-position-x: 93%;
  h1 {
    color: var(--bs-white);
  }
  h3 {
    font-weight: 500;
  }
}
@media (max-width: 992px) {
  .hero-tampa {
    background-position-x: revert;
    height: 70vh;
  }
}
@media (max-width: 767.98px) { 
  .hero-tampa {
    height: 100%;
    background-color: var(--bs-sand);
    padding-bottom: 36px;
  }
}
.daily-event {
  .daily-event-new {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -15px;
      bottom: 0;
      width: 1px;
      height: 90%;
      background-color: var(--bs-gallery);
    }
  }
}
.about-tampa {
  .content {
    @include media-breakpoint-down(lg) {
      min-height: 190px;
    }
  }
}