nav {
  .navbar {
    .navbar-toggler {
      position: absolute;
      right: 0;
    }
    .navbar-collapse {
      @include media-breakpoint-down(lg) {
        position: absolute;
        top: 44px;
        right: 0;
      }
      .main-nav {
        margin-bottom: 0;
        @include media-breakpoint-down(lg) {
          background-color: var(--bs-sand);
          padding: 1rem;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
          width: 200px;
        }
        li {
          margin: 0 6px;
          @include media-breakpoint-down(xl) {
            margin: 0 4px;
          }
          a {
            font-size: $font-size;
            font-weight: 500;
            font-family: $standard-family;
            @include media-breakpoint-down(xl) {
              font-size: $font-size - .1rem;
            }
          }
        }
      }
    }
  }
}