/** ===========================================
  # Bootstrap Height and Width Classes
  # This variable affects the `.h-*` and `.w-*` classes.
  # Iterate for what you need.
============================================ */
$sizes: () !default;
@for $i from 1 through 2000 {
  @if $i <= 100 {
    @if $i%5 == 0 {
      $holdingArrayPercent: (
      // Escape the Percentage Sign
      '#{$i}': #{$i}#{'%'}
      );
      $sizes: map-merge($sizes, $holdingArrayPercent);
      $holdingArrayPixels: (
      '#{$i}p': #{$i}px
      );
      $sizes: map-merge($sizes, $holdingArrayPixels);
    }
  }
  @if $i%10 == 0 {
    $holdingArrayPixels: (
    '#{$i}p': #{$i}px
    );
    $sizes: map-merge($sizes, $holdingArrayPixels);
  }
}

/** ===========================================
  # We make our own for MD Screen Size Width/Heights
============================================ */
$sizesMd: () !default;
$sizesMd: map-merge($sizes, $sizesMd);

@media (min-width: 768px) {
  @each $prop, $abbrev in (width: w-md, height: h-md, max-width: mw-md, max-height: mh-md, min-width: minw-md, min-height: minh-md) {
    @each $size, $length in $sizesMd {
        html .#{$abbrev}-#{$size} { #{$prop}: $length !important;
      }
    }
  }
}

/** ===========================================
  # We make our own for LG Screen Size Width/Heights
============================================ */
$sizesLg: () !default;
$sizesLg: map-merge($sizes, $sizesLg);

@media (min-width: 991px) {
  @each $prop, $abbrev in (width: w-lg, height: h-lg, max-width: mw-lg, max-height: mh-lg, min-width: minw-lg, min-height: minh-lg) {
    @each $size, $length in $sizesLg {
      html .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
    }
  }
}

/** ===========================================
  # We make our own for XL Screen Size Width/Heights
============================================ */
$sizesXL: () !default;
$sizesXL: map-merge($sizes, $sizesLg);

@media (min-width: 1200px) {
  @each $prop, $abbrev in (width: w-xl, height: h-xl, max-width: mw-xl, max-height: mh-xl, min-width: minw-xl, min-height: minh-xl) {
    @each $size, $length in $sizesLg {
      html .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
    }
  }
}

/** ===========================================
  # Loop through each breakpoint and provide classes 
  for the sizes within the sizes map from previous loop
============================================ */
@each $breakpoint-key, $breakpoint in $grid-breakpoints {
  @media (min-width: #{$breakpoint}) {
    @each $prop, $abbrev in (width: w-#{$breakpoint-key}, height: h-#{$breakpoint-key}, max-width: mw-#{$breakpoint-key}, max-height: mh-#{$breakpoint-key}, min-width: minw-#{$breakpoint-key}, min-height: minh-#{$breakpoint-key}) {
      @each $size, $length in $sizes {
        html .#{$abbrev}-#{$size} { #{$prop}: #{$length} !important;}
      }
    }
  }
}

@each $color, $value in $theme-colors {
  // Generate `.shadow-hover*` color utilities
  .shadow-hover-#{$color} {
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 0 4px #{$value};
    }
  }
  // Generate `.bg-hover*` color utilities
  .bg-hover-#{$color} {
    &:hover {
      background-color: #{$value};
    }
  }
  .btn-#{$color} {
    @include button-variant($value, $value,
      $hover-background: shade-color($value, 7.5%),
      $hover-border: shade-color($value, 10%),
      $active-background: shade-color($value, 10%),
      $active-border: shade-color($value, 12.5%)
    );
  }
}

[data-background] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
}