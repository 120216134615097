.event-tab {
  p {
    font-family: $standard-family;
  }
  .event-tab-content1 {
    &:before {
      content: '';
      position: absolute;
      left: 11px;
      top: -15px;
      bottom: 0;
      width: 1px;
      height: 90%;
      background-color: var(--bs-gallery);
    }
  }
}