/** ======================
  # Theme Type Settings
======================= */
$standard-family: 'Poppins', sans-serif;
$headings-family: 'Montserrat', sans-serif;
$helvetica-family: 'Helvetica', sans-serif;
$body-family: 'Raleway', sans-serif;
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts/";
$fa-font: 'Font Awesome 6 Free';
$font-size: 1rem;

// Use var(--bs-colorname)
// Use theme-color('deep-skyblue') for lighten, darken or rgba
/* =======================
  # Theme Color pallette
======================= */
$theme-colors: (
  'white': #fff,
  'alabaster': #f9f9f9,
  'sand': #f5f5f5,
  'gallery': #ececec,
  'celery-green': #b0c14d,
  'orange': #ff9a00,
  'spectra': #3b6b6e,
  'boulder': #777,
  'mine': #333,
  'black': #000,
);

@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 4),
    7: ($spacer * 5),
    8: ($spacer * 6),
    9: ($spacer * 7)
  ),
$spacers );

$grid-gutter-width: 30px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);