.hero {
  background: url('../images/Spa_Invitational_Hero__B_2021.jpg') center right/cover no-repeat;
  @include media-breakpoint-down(xl) {
    background-position-x: 97%;
  }
  @include media-breakpoint-down(lg) {
    background-position-x: 78%;
  }
  @include media-breakpoint-down(md) {
    background-image: none;
    background-color: var(--bs-sand);
  }
}