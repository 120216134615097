.buy-ticket {
  .ticket-info {
    .item {
      .column {
        @include media-breakpoint-down(md) {
          padding: 20px;
        }
        .price {
          @include media-breakpoint-down(md) {
            font-size: $font-size + 1rem;
          }
        }
      }
    }
  }
}
.travel-info {
  .content {
    @include media-breakpoint-down(lg) {
      min-height: 257px;
    }
    @include media-breakpoint-down(md) {
      min-height: 350px;
    }
  }
}