.header-slider,
.sponsors-slider {
  display: none;
  &.slick-initialized {
    display: block;
  }
  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sponsors-slider {
  .slick-slide {
    background-color: var(--bs-alabaster);
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid var(--bs-gray-200);
    .item {
      margin: 0;
      & {
        background-color: var(--bs-alabaster);
        height: 330px;
      }
    }
    img,
    h2 {
      width: 200px;
      margin: 0 auto;
    }
  }
}