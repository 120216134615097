#venue {
  .img-box {
    position: relative;
    overflow: hidden;
    &:hover {
      img {
        transform: scale(1.1,1.1);
        transition: .5s ease;
      }
    }
    img {
      position: relative;
      width: 100%;
      display: block;
      transition: .5s ease;
    }
  }
  .content {
    padding: 25px;
    border-bottom: 1px solid var(--bs-gallery);
    box-shadow: 0 1px 1px 1px var(--bs-sand);
  }
}