.header {
  position: relative;
  z-index: 99;
  
}
.transparent-bg.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  
}


/*================== main header ===============*/

.main-header {
  position:relative;
  width:100%;
  left:0px;
  top:0px;
  z-index:99;

  transition:all 300ms ease;
  -moz-transition:all 300ms ease;
  -webkit-transition:all 300ms ease;
  -ms-transition:all 300ms ease;
  -o-transition:all 300ms ease;   
}





.main-header.sticky-fixed {
position: fixed;
top: 0;
left: 0;
width: 100%;
z-index: 99999;
box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}



.main-header .logo {
  position: relative;
  /*margin-top: 28px;*/
  z-index: 9999;
  transition:all 300ms ease;
  -moz-transition:all 300ms ease;
  -webkit-transition:all 300ms ease;
  -ms-transition:all 300ms ease;
  -o-transition:all 300ms ease;   
max-width: 250px;

}
.logo img {
width: 100%;
}




/*=============== search =============*/
header .main-header .header-info {
  position: absolute;
  right: 0;
  top: 8px;
}



header .main-header .header-info .top-search {
  position: relative;
  padding: 19px 5px;
  cursor: pointer;
  display: inline-block;
  margin-right: 31px;

}


header .main-header .header-info .top-search:after {
  position: absolute;
  content: "";
  top: 20px;
  right: -17px;
  height: 25px;
  width: 1px;
  background: #cacbca;
}
header .main-header .header-info .top-search:before {
  position: absolute;
  content: "";
  top: 20px;
  left: -15px;
  height: 25px;
  width: 1px;
  background: #cacbca;
}



header .main-header .header-info .top-search span:before {
  color: #fff;
  font-size: 14px;
  margin: 0;
}
header .main-header .header-info .top-search span.search:before {
  color: #ccc;
  font-size: 17px;
  margin: 0;
}

header .main-header .header-info .top-search span.search:before {
  color: #fff;
  font-size: 17px;
  margin: 0;
}

header .main-header .header-info .cart a span.count {
  background: #67c52b;
  padding: 2px 5px;
  border-radius: 50%;
  top: -10px;
  font-weight: 600;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  left: -8px;
  font-size: 10px;
}

header .main-header .header-info .top-search .search-box {
position: absolute;
width: 250px;
background: #ff9a00;
top: 100%;
right: 0;
list-style: none;
margin: 0;
padding: 0;
padding: 10px;
opacity: 0;
visibility: hidden;
transition: all 0.5s ease-in;
z-index: 9999;
}

header .main-header .header-info .top-search:hover .search-box {
  transition: .5s ease-in-out;
  visibility: visible;
  opacity: 1;

}

header .main-header .header-info .top-search .search-box form {
background: #fff;
}

header .main-header .header-info .top-search .search-box li {
list-style: none;
}

header .main-header .header-info .top-search .search-box li input {
width: 78%;
background-color: transparent;
border: none;
outline: none;
height: 40px;
padding-left: 15px;
color: #666;
}

header .main-header .header-info .top-search .search-box li input::-webkit-input-placeholder {
 color: #999;
 font-weight: normal;
 font-family: 'Raleway', sans-serif;
 font-size: 14px;
}

header .main-header .header-info .top-search .search-box li input:-moz-placeholder {
  color: #999;
  font-weight: normal;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
}

header .main-header .header-info .top-search .search-box li input:-ms-input-placeholder {
  color: #999;
  font-weight: normal;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
}    

header .main-header .header-info .top-search .search-box li button {
background: none;
outline: none;
color: #A9A9A9;
border: none;
width: 30px;
height: 40px;
margin-right: 5px;
}



header .main-header .header-info .cart {
  position: relative;
  display: inline-block;
  top: 1px;
}

header .main-header .header-info .cart a span {
  position: relative;
  color: #fff;
}


















/*mainmenu area=======================*/

@media (max-width: 991.98px) {
.logo{
display: block;
  margin-right: auto;
  margin-left: auto;
}
.main-header .nav-outer {
  float: none;;
}
}

.main-header .nav-outer {
  position: relative;
}
@media (min-width: 992px) { 
.main-header .nav-outer {
  float: right;
}
}
.main-header.sticky-fixed {
  padding-top: 0px;
}


.mainmenu-area .navbar-collapse > ul > li:hover > a {
  color: #ff9a00;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}


.navbar {
  position: relative;
  margin-bottom:0px;
  border: 0px solid transparent;
}
.mainmenu-area {
  position: relative;
}


.navbar .navbar-collapse > ul {
padding: 0;
margin: 0;
  /*margin-top: 30px;*/
}

.navbar .navbar-collapse > ul > li {
display: inline-block;
position: relative;
  margin: 0 10px;
}

.navbar .navbar-collapse > ul > li > a {
  display: block;
  font-size: 1.1rem;
  font-weight: normal;
  text-transform: uppercase;
  color: #000;
  /*padding-bottom: 30px;*/
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

@media (min-width: 1200px) {
.navbar .navbar-collapse > ul > li > a {
  font-size: 1.5rem;
}
}



.navbar .navbar-collapse > ul > li:hover > ul {
opacity: 1;
visibility: visible;
transition: .5s;
}
.navbar .navbar-collapse > ul > li > ul {
  position: absolute;
  top:100%;
  left:0;
  z-index: 999;
  background: #fff;
  width: 240px;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 13px 42px 11px rgba(0,0,0,.05);
  border: 1px solid #EEE;
  border-top: 2px solid #ff9a00;
  border-bottom-left-radius: 5px;
  transition: all .5s ease;
}

.navbar .navbar-collapse > ul > li > ul > li {
display: block;
position: relative;
}
.navbar .navbar-collapse > ul > li > ul > li:last-child > a {
border-bottom: 0;
}
.navbar .navbar-collapse > ul > li > ul > li > a {
display: block;
font-size: 14px;
  font-weight: 600;
text-transform: capitalize;
color: #232323;
padding: 10px 19px;
transition: all .3s ease;
  text-align: left;
}

.navbar .navbar-collapse > ul > li > ul > li:hover > a {
background: #F9F9F9;
color: #ff9a00;
padding-left: 23px;
}
.navbar .navbar-collapse > ul > li > ul > li:hover > ul {
opacity: 1;
visibility: visible;
}
.navbar .navbar-collapse > ul > li > ul > li > ul {
position: absolute;
top:0;
left:100%;
width: 240px;
margin: 0;
padding: 0;
opacity: 0;
visibility: hidden;
z-index: 999;
background: #fff;
box-shadow: 2px 0 11px -3px rgba(0,0,0,.2);
transition: all .5s ease;
}
.navbar .navbar-collapse > ul > li > ul > li > ul > li {
display: block;
position: relative;
}
.navbar .navbar-collapse > ul > li > ul > li > ul > li:last-child > a {
border-bottom: 0;
}
.navbar .navbar-collapse > ul > li > ul > li > ul > li > a {
display: block;
font-size: 14px;
  font-weight: 600;
text-transform: capitalize;
color: #232323;
padding: 10px 19px;
transition: all .3s ease;
  text-align: left;
}
.navbar .navbar-collapse > ul > li > ul > li > ul > li:hover > a {
background: #F9F9F9;
color: #ff9a00;
border-color: 0;
padding-left: 23px;
}


.navbar .navbar-collapse ul > li.dropdown .dropdown-btn{
position:absolute;
right:10px;
top:6px;
width:34px;
height:30px;
border:1px solid #ffffff;
// background: url(/core/fileparse.php/107499/urlt/../images/icons/submenu-icon.png) center center no-repeat;
background-size:20px;
cursor:pointer;
z-index:5;
display:none;
border-radius:3px;
-webkit-border-radius:3px;
-ms-border-radius:3px;
-o-border-radius:3px;
-moz-border-radius:3px;
}










/*** 

====================================================================
Main Slider style
====================================================================

***/


.rev_slider_wrapper {
position: relative;
overflow: hidden;
}

.rev_slider_wrapper ul li.overly1:after {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  /*background:url(/core/fileparse.php/107499/urlt/../images/home-slide.jpg) center center no-repeat;*/
  z-index: 0;
}


.rev_slider_wrapper h2 {
  position: relative;
  font-size: 90px;
  font-family: 'Montserrat', sans-serif;
  color: #b0c14d;
  font-weight: normal !important;
}

.rev_slider_wrapper p {
  position: relative;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  color: #63619a;
  line-height: 31px;
}


.rev_slider_wrapper h3 {
  position: relative;
  font-size: 30px !important;
  font-weight: normal;
  color: #63619a;
  font-family: 'Montserrat', sans-serif;
}
.rev_slider_wrapper img {
width: 120px !important;
  height: auto !important;
}
.rev_slider_wrapper .btn-purp {
font-size: 16px !important;
  padding: 10px 20px !important;
} 
.rev_slider_wrapper .btn-lg {
padding: 10px 16px !important;
  line-height: 1.3333333 !important;
}
.rev_slider_wrapper a.btn-style-one {
  position: relative;
  padding: 12px 27px;
}

.rev_slider_wrapper a.btn-style-one.bg-color-2:hover {
  background: #ff9a00;
  color: #fff;
  
}

.rev_slider_wrapper a.btn-style-one.bg-color-1:hover {
  background: #ff9a00;
  color: #fff;
}

/*style-2*/

.style-2.rev_slider_wrapper h2 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 15px;
}


/*** 

====================================================================
  countdown-timer style
====================================================================

***/

.countdown-timer {
  position: relative;
  top: -50px;
      margin-bottom: 2rem;
}

.countdown-timer .countdown {
  background: #b0c14d;
  margin: 0 85px;
  text-align: center;
}


.countdown-timer li {
  position: relative;
  display: inline-block;
  padding: 5px 0px;
  width: 25%;
  text-align: center;
  margin: 24px -2px;
  border-right: 1px dashed #fff;
}


.countdown-timer li:last-child {
  border-right: 0px;
  
}



.countdown-timer li span.days,
.countdown-timer li span.hours,
.countdown-timer li span.minutes,
.countdown-timer li span.seconds {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  color: #fff;
  font-weight: 600;
}

.countdown-timer li span.timeRef {
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

/*style-2*/

.style-2.countdown-timer {
  background: rgba(4, 4, 4, 0.5);
  top: 0;
  margin-top: -125px;
}
.style-2.countdown-timer h2 {
  position: relative;
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  line-height: 50px;
  margin-top: 38px;
}
.style-2.countdown-timer .countdown {
  margin: 0px;
  background: transparent;
  padding: 12.5px 0;
}

.style-2.countdown-timer li:first-child {
  padding-left: 0;
}

.style-2.countdown-timer li:last-child {
  padding-right: 0;

}

.style-2.countdown-timer li span.days {
  color: #f7e415;
}

.style-2.countdown-timer li span.hours {
  color: #5fd426;
}


.style-2.countdown-timer li span.minutes{
  color: #31dbda;
}

.style-2.countdown-timer li span.seconds {
  color: #ff9a00;
}








/*** 

====================================================================
  about style
====================================================================

***/
.about {
  position: relative;
  padding-top: 25px;
  padding-bottom: 47px;
}

.about .single-column {
  position: relative;
  margin-bottom: 50px;
}

.about .section-title, .event-tab-title {
  padding-bottom: 56px;
}



.about .img-box {
  position: relative;
  overflow: hidden;
}
.about .img-box:hover img {
  transform: scale(1.1,1.1);
  transition: .5s ease;
}

.about .img-box img {
  position: relative;
  width: 100%;
  display: block;
  transition: .5s ease;
}


.about .content {
  position: relative;
  border-bottom: 1px solid #ebebeb;
  padding: 25px 26px;
  box-shadow: 0px 1px 1px 1px #f1f1f1;
}

.about .content h2 {
  position: relative;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  line-height: 26px;
}
.about .content h2 a {
  color: #2b3033;
  transition: .5s ease;
}

.about .content h2 a:hover {
  color: #ff9a00;
  transition: .5s ease;
}
.about .content h3 {
  position: relative;
  font-size: 14px;
  color: #f96921;
  margin-top: 42px;
  margin-bottom: 13px;
}

.about .content p {
  margin-bottom: 7px;
  margin-top: 4px;
  position: relative;
  font-size: 14px;
  color: #777;
  font-family: 'Lora', serif;
  font-style: italic;
}


.about .content p span {
  color: #333;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
}

.about .content .btn-style-default {
  color: #ff9a00;
  font-size: 13px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

.about .section-title h2{
margin-bottom: 11px;
}


/*** 

====================================================================
  team Section
====================================================================

***/


.our-team {
  position: relative;
  padding: 125px 0 91px;
}
.our-team:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  // background:url(/core/fileparse.php/107499/urlt/../images/overly/team.png) center center no-repeat;
  background-size: cover;
  z-index: 0;
}

.our-team .section-title {
  padding-bottom: 50px;
}


.our-team .section-title h3,
.our-team .section-title h2 {
  color: #fff;
}


.our-team .single-team .img-holder {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.our-team .single-team .img-holder img {
  width: 100%;
}



.our-team .single-team .img-holder .overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: rgba(54,63,74, .75);
  text-align: center;
  opacity: 0;
  transition: all .3s ease;
}
.our-team .single-team:hover .img-holder .overlay {
  opacity: 1;
}
.our-team .single-team .img-holder .overlay .inner {
  display: table;
  height: 100%;
  position: relative;
  width: 100%;
}
.our-team .single-team .img-holder .overlay .inner .content {
  vertical-align: middle;
  display: table-cell;
}
.our-team .single-team .img-holder .overlay h2 {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: #ff9a00;
  text-transform: capitalize;
}

.our-team .single-team .img-holder .overlay h3 {
  position: relative;
  margin-top: 6px;
  margin-bottom: 3px;
  font-size: 15px;
  font-weight: 400;
  color: #bbb;
  font-style: italic;
}


.our-team .single-team .img-holder .overlay .social {
  position: relative;
}
.our-team .single-team .img-holder .overlay .social li {
  display: inline;
  margin: 0 6px;
}
.our-team .single-team .img-holder .overlay .social li a {
  display: inline-block;
  text-align: center;
  font-size: 15px;
  line-height: 30px;
  color: #fff;
  transition: .5s ease-in;
}
.our-team .single-team .img-holder .overlay .social li a:hover {
  color: #ff9a00;
  transition: .5s ease-in;
}
/*style-2*/
.our-team.style-2 {
  position: relative;
  padding: 120px 0 90px;
}
.our-team.style-4 .section-title,
.our-team.style-3 .section-title,
.our-team.style-2 .section-title {
  padding-bottom: 50px;
}
.our-team.style-4,
.our-team.style-3 {
  position: relative;
  padding: 120px 0 90px;
}

.our-team.style-3:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  // background:url(/core/fileparse.php/107499/urlt/../images/overly/team-2.png) center center no-repeat;
  background-size: cover;
  z-index: 0;
}


.our-team.style-4 .section-title h2,
.our-team.style-3 .section-title h2 {
  color: #333;
}
.our-team.style-4 .section-title h3,
.our-team.style-3 .section-title h3 {
  color: #777;
}

/*style-4*/

.our-team.style-4:before {
  display: none;
}
.style-4.our-team .single-team .img-holder .overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;  
  text-align: center;
  opacity: 0;
  transition: all .3s ease;
}
.style-4.our-team .single-team:hover .img-holder .overlay-2 {
  opacity: 1;
}
.style-4.our-team .single-team .img-holder .overlay-2 .inner {
  display: table;
  width: 100%;
  height: 100%;
  position: relative;
}

.style-4.our-team .single-team .img-holder .overlay-2 .info {
  display: table-cell;
  vertical-align: bottom;
  padding-bottom: 43px;
  position: relative;
}
.style-4.our-team .single-team .img-holder .overlay-2 .info a {
  position: relative;
  color: #ff9a00;
  background: #000;
  display: inline;
  font-weight: 600;
  padding: 12px 33px;
  font-size: 16px;
}

.style-4.our-team .single-team .content-holder {
  text-align: center;
  background: #fff;
  display: block;
  overflow: hidden;
}



.style-4.our-team .single-team .content-holder:before {
  background: #ff9a00;
}
.style-4.our-team .single-team:hover .content-holder {
  border-top-color: #282F39;
  transition: .5s ease;
}


.style-4.our-team .single-team:hover .content-holder:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}




.our-team.style-4 .single-team .img-holder {
  margin-bottom: 0px;
}


.style-4.our-team .single-team .content-holder h4 {
  text-transform: capitalize;
  color: #333;
  margin: 0;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 3px;
  transition: all .3s ease;
}
.style-4.our-team .single-team .content-holder h4 a {
  color: #333;
}
.style-4.our-team .single-team:hover .content-holder h4 a {
  color: #fff;
}


.style-4.our-team .single-team .content-holder p {
  color: #777;
  font-family: "Lora", serif;
  font-style: italic;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 19px;
}
.style-4.our-team .single-team:hover .content-holder p {
  color: #fff;
}





/*** 

====================================================================
  daily event
====================================================================

***/
.daily-event {
  position: relative;
  padding-top: 20px;
  padding-bottom: 69px;
}

.daily-event.overly:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  // background:url(/core/fileparse.php/107499/urlt/../images/overly/daily-event.png) center center no-repeat;
  background-size: cover;
  z-index: 0;
}




.daily-event .event-tablist {
  position: relative;
  padding: 32px 0 75px;
}

.daily-event .event-tablist li {
  display: inline-block;
  border: 1px solid #ececec;
  margin: 0 -3px;
  padding: 23px 35px;
  font-size: 15px;
  border-right: 0px;
}

.daily-event .event-tablist li:last-child {
  border-right: 1px solid #ececec;
}

.daily-event .event-tablist li.active a,
.daily-event .event-tablist li a:hover {
  color: #ff9a00;
  transition: .5s ease;
}
.daily-event .event-tablist li a {
  color: #777;
  transition: .5s ease;
}
.daily-event .event-tablist li a span {
  font-size: 8px;
  position: relative;
  top: -5px;
}

.daily-event .author{
  position:relative;
  padding:0px 0px 0px 60px;
  color:#777;
  line-height:20px;
}

.daily-event .author .img-box {
  position:absolute;
  left:0px;
  top:-7px;
}
.daily-event .author .img-box img {
  border-radius: 50%;
}


.daily-event .author h4 {
  color:#333;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 3px;
}

.daily-event .nav-pills>li.active .author h4 {
  color:#fff;
}


.daily-event .author p {
  position:relative;
  font-size:12px;
  line-height: 15px;
}

.daily-event .nav-pills>li.active .author p {
  color: #ff9a00;
}
.daily-event .event-tab1 .column-1 {
margin-top: 12px;
}

.daily-event .event-tab1 .column-2 {
  padding-left: 45px;
}

.daily-event .post-content {
  position:relative;
  padding-left: 105px;
  padding-bottom: 10px;
  padding-top: 15px;
}
.daily-event .post-content:after {
  position: absolute;
  content: "";
  left: -7px;
  top: 27px;
  bottom: 0;
  width: 14px;
  height: 14px;
  background: #fff;
  border: 2px solid #b0c14d; /*#ff9a00*/
  border-radius: 50%;
}
.event-tab1 .tab-pane.active .post-content:after {
  position: absolute;
  content: "";
  left: -7px;
  top: 27px;
  bottom: 0;
  width: 14px;
  height: 14px;
  background: #ff9a00;
  border: 2px solid #ff9a00;
  border-radius: 50%;
}
.daily-event .post-content .section-title h2 {
  font-size: 18px;
  margin-bottom: 14px;
  color: #333;
}
.event-tab1 .tab-pane.active .post-content .section-title h2 {
  color: #ff9a00;
}

.daily-event .post-content .section-title h3 {
  font-size: 17px;
  color: #777;
  margin-bottom: 18px
}


.daily-event .post-content .text {
  overflow: hidden;
  margin-bottom: 41px;
}

.daily-event .post-content .text p {
  color: #777;
  margin-bottom: 18px;
  font-size: 14px;
}


.daily-event .post-content .text a {
  font-size: 15px;
  color: #ff9a00;
  background: transparent;
  padding: 0;
  font-family: 'Lora', serif;
  font-style: italic;
}





.daily-event .nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover {
  color: #fff;
  background-color: #363f4a;
}

.daily-event .nav-pills>li {
  margin-bottom: 35px;
}

.left-menu-3{
    margin-bottom: 170px;
}


.daily-event .nav-pills>li>a {
  border-radius: 70px;
  background: #f9f9f9;
  padding: 16px 30px;
  padding-left: 12px;
}




/*** 

====================================================================
  call-out
====================================================================

***/

.call-out {
  position:relative;
  padding: 65px 0px 45px;
  background:#303511;
  color:#ffffff;  
}


.call-out .column {
  /*margin-bottom:20px;*/
  font-size:18px;
  font-weight:300;
}

.call-out .column h2 {
  font-size:22px;
  margin-bottom:10px;
  font-weight:600;
  color:#ffffff;
}
.call-out .column a.theme-btn:hover {
  background: #ff9a00;
  color: #fff;
}

.call-out .column p {
  font-size:18px;
  margin-bottom:10px;
  color:#f9f9f9;
}






/*** 

====================================================================
  buy ticket style
====================================================================

***/





.buy-ticket {
  // position: relative;
  padding: 80px 0 20px;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center center;
}
.buy-ticket:before {
  // content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  /*
  background:url(/core/fileparse.php/107499/urlt/../images/buy-ticket.jpg) center center no-repeat;
  background-size: cover;
  */
  z-index: 0;
}
.buy-ticket .section-title {
  position: relative;
  margin-bottom: 60px;
}

.buy-ticket .form-area {
  padding: 40px 50px;
  background: #fff;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.10), 2px 2px 5px 2px rgba(0, 0, 0, 0.12);
}


.buy-ticket .form-area h2 {
  position: relative;
  font-size: 19px;
  font-weight: 600;
  color: #333;
  text-transform: capitalize;
  margin-bottom: 27px;
  margin-top: 4px;
}
.buy-ticket .form-area h4 {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  text-transform: capitalize;
  margin-bottom: 0px;
  margin-top: 4px;

}

.buy-ticket .ticket-form {
  position: relative;
}




.buy-ticket .ticket-form .btn-style-two {
  border-color: #a263c6;
  color: #a263c6;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0px;
  width: 100%;
  line-height: inherit;
  padding: 13px;
}
.buy-ticket .ticket-form .btn-style-two:hover {
  border-color: #a263c6;
  color: #fff;
  background: #a263c6;
  transition: .5s ease;
}


.buy-ticket .ticket-form input[type="text"],
.buy-ticket .ticket-form input[type="email"],
.buy-ticket .ticket-form input[type="password"],
.buy-ticket .ticket-form select{
  position:relative;
  width:100%;
  line-height:24px;
  font-size: 12px;
  color:#777;
  background:#fff;
  border: 1px solid #bfbfbf;
  height: 50px;
  padding: 0px 20px;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

.buy-ticket .ticket-form textarea{
  position:relative;
  width:100%;
  line-height:24px;
  padding: 20px 20px 0;
  height: 200px;
  resize:none;
  background:#fff;
  border: 1px solid #eee;
  font-size: 12px;
  color: #777;
  padding-bottom:0px;
  margin-bottom:35px;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}



.buy-ticket .ticket-form input:focus,
.buy-ticket .ticket-form textarea:focus,
.buy-ticket .ticket-form select:focus{
  border-color:#ff9a00;   
}

.buy-ticket .ticket-form input.error,
.buy-ticket .ticket-form textarea.error,
.buy-ticket .ticket-form select.error{
  border-color:#ff9a00;
}

.buy-ticket .ticket-form input.error:focus,
.buy-ticket .ticket-form textarea.error:focus{
  border-color:#ff9a00;
  box-shadow:0px 0px 3px 0px #ff9a00;
  -moz-box-shadow:0px 0px 3px 0px #ff9a00;
  -ms-box-shadow:0px 0px 3px 0px #ff9a00;
  -o-box-shadow:0px 0px 3px 0px #ff9a00;
  -webkit-box-shadow:0px 0px 3px 0px #ff9a00;
}
.buy-ticket .ticket-form label.error{
  display:none !important;
}


.buy-ticket .ui-selectmenu-button span.ui-selectmenu-text {
  font-size: 13px;
  font-family: 'Lora', serif;
  background: #fff;
  color: #777;
  font-weight: normal; 
  font-style: italic;
  padding: 15px 20px 16px;   
}


.buy-ticket .ui-corner-all {
  border-radius: 0;
  border-color: #eee;
}

.buy-ticket .ui-corner-top {
  border-radius: 0;
  border-color: #ff9a00;
}


.ui-menu .ui-menu-item {
  font-size: 14px;
  font-family: 'Asap', sans-serif;
  color: #777;
  font-weight: normal;
  border: none;
  padding: 10px 20px;
}


.ui-widget-content .ui-state-focus {
  font-size: 14px;
  font-family: 'Asap', sans-serif;
  color: #fff;
  font-weight: normal;
  background: #ff9a00;
}

.ui-selectmenu-menu .ui-menu {
  background: #fff;
  border-radius: 0px;
  border-color: #ececec;
}


.buy-ticket.ui-selectmenu-button span.ui-icon {
  background-image: none;
}
.buy-ticket .select-menu + .ui-selectmenu-button:before {
  position: absolute;
  content: "\f107";
  font-family: FontAwesome;
  right: 19px;
  top: 14px;
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: #b5b5b5;
}
.buy-ticket .ui-icon-triangle-1-s {
  background-position: 5000px;
}



.buy-ticket .ticket-form .field-label {
  display: block;
  text-transform: capitalize;
  font-family: 'Raleway',sans-serif;
  color: #777;
  font-size: 16px;
  margin-bottom: 9px;
}
.buy-ticket .subscribe-check .check-boxe {
  position: relative;
  color: #777;
  font-size: 13px;
  font-family: 'Raleway', sans-serif;
  margin-top: 30px;
  margin-bottom: 34px;
}
input[type="checkbox"] {
outline: 0 !important;
border: 0 !important;
box-shadow: none !important;
}
.buy-ticket .subscribe-check .check-boxe input[type="checkbox"] {
  position: relative;
  top: 2px;
  margin: 0;
  margin-right: 11px;
}

.buy-ticket .ticket-info {
  position: relative;
}
.buy-ticket .ticket-info .item {
  position: relative;
  background: #fff;
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.10), 1px 2px 5px 1px rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 40px;
}

.buy-ticket .ticket-info .item .column{
  position: relative;
  padding: 20px 0;
  
  /*padding-left: 50px;*/
}

.buy-ticket .ticket-info .item .price {
  position: relative;
  font-weight: 600;
  font-size: 40px;
  color: #333;
      text-align: center;
}

.count {
text-align: center;
}

/*.buy-ticket .ticket-info .item .price span {
  font-size: 18px;
  font-weight: 500;
      text-align: center;
}
*/

.buy-ticket .ticket-info .item .coumt {
  position: relative;
  font-size: 14px;
  color: #333;
  font-weight: 300;
}
.buy-ticket .ticket-info .item .right-column {
  padding-left: 35px;
  border-left: 1px solid #ececec;
}
.buy-ticket .ticket-info .item .row-7 {
  margin-right: -7px;
  margin-left: -7px;
  overflow: hidden;
}
.buy-ticket .ticket-info .item .row-7 .column-7 {
  padding-right: 7px;
  padding-left: 7px;
}

.buy-ticket .ticket-info .item h2 {
  position: relative;
  font-size: 19px;
  font-weight: 600;
  color: #333;
  text-transform: capitalize;
  margin-bottom: 13px;
  margin-top: 22px;
}


.buy-ticket .ticket-info .item .list {
  position: relative;
}
.buy-ticket .ticket-info .item .list li {
  position: relative;
  font-family: "Raleway", sans-serif;
  font-size: 13px;
  color: #777;
  left: 10px;
  margin-bottom: 10px;
}

.buy-ticket .ticket-info .item .list li span:before {
  font-size: 10px;
  color: #b0c14d; /*#ff9a00*/
  left: -10px;
  position: relative;
}

.buy-ticket .ticket-info .item .btn-style-default {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #ff9a00;
  text-transform: capitalize;
  line-height: 40px;
}




/*** 

====================================================================
  travel-info style
====================================================================

***/
.travel-info {
  position: relative;
  padding-top: 120px;
  padding-bottom: 72px;
}
.travel-info .section-title {
  position: relative;
  padding: 0px 0px 56px;
}
.travel-info .single-column {
  position: relative;
  margin-bottom: 50px;
}

.travel-info .section-title {
  padding-bottom: 56px;
}



.travel-info .img-box {
  position: relative;
  overflow: hidden;
}
.travel-info .img-box:hover img {
  transform: scale(1.1,1.1);
  transition: .5s ease;
}

.travel-info .img-box img {
  position: relative;
  width: 100%;
  display: block;
  transition: .5s ease;
}


.travel-info .content {
  position: relative;
  border-bottom: 1px solid #ebebeb;
  padding: 25px;
  padding-bottom: 28px;
  padding-top: 29px;
  box-shadow: 0px 1px 1px 1px #f1f1f1;
}

.travel-info .content h2 {
  position: relative;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin-bottom: 6px;
  line-height: 26px;
}
.travel-info .content h2 a {
  color: #2b3033;
  transition: .5s ease;
}

.travel-info .content h2 a:hover {
  color: #ff9a00;
  transition: .5s ease;
}
.travel-info .content h3 {
  position: relative;
  font-size: 14px;
  color: #f96921;
  margin-top: 42px;
  margin-bottom: 13px;
}

.travel-info .content p {
  margin-bottom: 7px;
  margin-top: 4px;
  position: relative;
  font-size: 13px;
  color: #777;
  line-height: 22px;
}


.travel-info .content h4 {
  margin-bottom: 7px;
  position: relative;
  font-size: 12px;
  color: #777;
}



.travel-info .rating {
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}
.travel-info .rating span {
  position: relative;
  color: #ff9a00;
  font-size: 12px;
  font-weight: 700;
}
.travel-info .rating span.deactive {
  position: relative;
  color: #b9b9b9;
  font-size: 12px;
}

.travel-info .content .btn-style-default {
  color: #333;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  margin-right: 15px;
}
.travel-info .content .btn-style-default:hover {
  color: #ff9a00;
  transition: .5s ease;
}



/*** 

====================================================================
 accomodation style
====================================================================

***/
.accomodation {
  position: relative;
  padding-top: 45px;
  padding-bottom: 72px;
}
.accomodation .section-title {
  position: relative;
  padding: 0px 0px 56px;
}
.accomodation .single-column {
  position: relative;
  margin-bottom: 50px;
}

.accomodation .section-title {
  padding-bottom: 56px;
}



.accomodation .img-box {
  position: relative;
  overflow: hidden;
}
.accomodation .img-box:hover img {
  transform: scale(1.1,1.1);
  transition: .5s ease;
}

.accomodation .img-box img {
  position: relative;
  width: 100%;
  display: block;
  transition: .5s ease;
}


.accomodation .content {
  position: relative;
  border-bottom: 1px solid #ebebeb;
  padding: 40px 34px 31px;
  box-shadow: 0px 1px 1px 1px #f1f1f1;
}
.accomodation .content .icon {
  position: absolute;
  left: 34px;
  top: 45px;
}

.accomodation .content .info {
  position: relative;
  padding-left: 30px;
}
.accomodation .content h2 {
  position: relative;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin-bottom: 6px;
  line-height: 26px;
}
.accomodation .content h2 a {
  color: #2b3033;
  transition: .5s ease;
}

.accomodation .content h2 a:hover {
  color: #ff9a00;
  transition: .5s ease;
}
.accomodation .content h3 {
  position: relative;
  font-size: 14px;
  color: #f96921;
  margin-top: 42px;
  margin-bottom: 13px;
}

.accomodation .content p {
  margin-bottom: 7px;
  margin-top: 4px;
  position: relative;
  font-size: 13px;
  color: #777;
  line-height: 22px;
}

/***

====================================================================
  sponsors section
====================================================================

***/

.sponser-section {
  position: relative;
  background: #f9f9f9;
  text-align: center;
}


.sponser-section .owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}
.sponser-section .owl-carousel .owl-item {
  padding: 72px 0px;
  display: inline-block;
  float: inherit;
}
.sponser-section .owl-carousel .owl-item.active:after {
  position:absolute;
  content: "";
  right:-1px;
  top:-50px;
  bottom: 0;
  width:1px;
  height:300px;
  background: #eaeaeb;
}

/*style-2*/

.style-2.sponser-section {
  padding: 120px 0 65px;
  background: #fff;
}
.style-2.sponser-section.no-pdt {
  padding-top: 0px;
}


.style-2.sponser-section .owl-carousel .owl-item.active:after {
  display: none;
}
.style-2.sponser-section .owl-carousel .owl-item {
  padding: 53px 0px;
  display: inline-block;
  float: inherit;
}

.diveder .border-bottom {
  border-bottom: 1px solid #ececec;
}

.no-pdt.style-2.sponser-section {
  padding-top: 0px;
}

/*sponsors-three-column*/


.sponser-three-column {
  position: relative;
  padding: 120px 0;
}
.sponser-three-column.no-pdt {
  padding-top: 0px;
}

.sponser-three-column .section-title {
  padding-bottom: 57px;
}

.sponser-three-column .owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}
.sponser-three-column .owl-carousel .owl-item .img-box {
  line-height: 60px;
  margin-bottom: 29px;
}

.sponser-three-column .owl-carousel .owl-item {
  border: 1px solid #ececec;
  padding: 40px 35px 50px;
}

.sponser-three-column .post-content h4 {
  font-size: 16px;
  margin-bottom: 27px;
  font-weight: 600;
}


.sponser-three-column .post-content p {
  color: #777;
  font-size: 14px;
  margin-bottom: 19px;
}

.sponser-three-column .post-content  a {
  font-size: 13px;
  font-weight: 600;
  color: #ff9a00;
  text-transform: capitalize;
}




/*** 

====================================================================
  recent-news style
====================================================================

***/
.recent-news {
  position: relative;
  background: #F9F9F9;
}
.recent-news .section-title h2{
  color: #fff;
}
.recent-news .section-title h3{
  color: #aaa;
}

.recent-news .top-area {
  position: relative;
  padding: 118px 0 122px;
}
.recent-news .top-area:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  // background:url(/core/fileparse.php/107499/urlt/../images/overly/blog.png) center center no-repeat;
  background-size: cover;
  z-index: 0;
}

.recent-news .single-column {
  position: relative;
  margin-bottom: 44px;
  top: -66px;
}

.recent-news .content {
  position: relative;
  background: #fff;
  padding: 65px 40px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.10), 0 2px 5px 0 rgba(0, 0, 0, 0.12);

}
.recent-news .content h2 {
  position: relative;
  font-size: 16px;
  color: #333;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 12px;
}
.recent-news .content h2 a {
  color: #333;
  transition: .5s ease;
}
.recent-news .content h2 a:hover {
  color: #ff9a00;
  transition: .5s ease;
}

.recent-news .content h4 {
  position: relative;
  font-size: 12px;
  color: #777;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 22px;
}
.recent-news .content h4 .separet {
  position: relative;
  padding: 0 7px;
}
.recent-news .content h4 .separet:after {
  position:absolute;
  content: "";
  left:5px;
  top:0px;
  bottom: 0;
  width:1px;
  height:100%;
  background: #ff9a00;
}

.recent-news .content p {
  position: relative;
  font-size: 14px;
  color: #777;
  line-height: 24px;
  margin-bottom: 17px;
}
.recent-news .content a.btn-style-default {
  position: relative;
  font-size: 13px;
  color: #ff9a00;
  transition: .5s ease;
}

.recent-news .content a.btn-style-default span:before {
  font-size: 18px;
  position: relative;
  left: 6px;
  top: 2px;
}





/*** 

====================================================================
  google-map Section
====================================================================

***/

.map-section {
  position: relative;
}


.map-section .google-map {
  position: relative;
  width: 100%;
  height: 540px;
  background: #d0d0d0;
}

/*style-2*/
.map-section.style-2 {
  position: relative;
  padding-top: 120px;
}
.map-section.style-2 .section-title {
  padding-bottom: 50px;
}


.map-section.style-2 .por {
  position: relative;
}

.map-section .map-info {
  position: absolute;
  background: #fff;
  padding: 40px;
  max-width: 370px;
  text-align: center;
  margin-left: -185px;
  margin-bottom: -110px;
  bottom: 50%;
  left: 50%;
  z-index: 9;
}
.map-section .map-info h2 {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}
.map-section .map-info p {
  font-size: 14px;
  color: #777;

}

.map-section .map-info .curve-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -30px;
  margin-bottom: -30px;
  height: 30px;
  width: 60px;
  overflow: hidden;
}

.map-section .map-info .curve-bottom:after {
  position: absolute;
  content: '';
  top: -42px;
  left: 0;
  height: 60px;
  width: 60px;
  background: #fff;
  transform: rotate(45deg);
}

/*** 

====================================================================
  contact us
====================================================================

***/



.contact-us {
  position: relative;
  padding: 122px 0 75px;
}
.contact-us .section-title {
  margin-bottom: 46px;
}
.contact-us .item {
  padding-left: 50px;
}

.contact-us h2 {
  position: relative;
  font-size: 16px;
  color: #333;
  line-height: 26px;
  font-weight: 500;
}
.contact-us h2 span {
  position: relative;
  margin-left: -26px;
  left: -17px;
  top: 14px;
}
.contact-us h2 span:before {
  position: relative;
  font-size: 25px
}

.contact-us h3 {
  position: relative;
  margin-bottom: 20px;
  font-size: 15px;
  color: #777;
  font-style: italic;
}
.contact-us h4 {
  position: relative;
  font-size: 12px;
  color: #777;
  line-height: 20px;
  margin-bottom: 22px;
}

.contact-us .contact-box {
  position: relative;
  margin-bottom: 20px;
}
.contact-us .contact-box .contact-form {
  margin-top: 6px;
}

.contact-us .contact-box .form-group {
  position: relative;
  margin-bottom: 25px;
  overflow: hidden;
}

.contact-us .contact-box input[type="text"],
.contact-us .contact-box input[type="email"],
.contact-us .contact-box input[type="password"],
.contact-us .contact-box select {
  position: relative;
  width: 100%;
  line-height: 24px;
  font-size: 14px;
  font-family: 'Open sans', sans-serif;
  color: #777;
  background: #fafafa;
  border: 1px solid #ececec;
  border-radius: 3px;
  height: 60px;
  padding: 24px 36px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.contact-us .contact-box textarea {
  position: relative;
  width: 100%;
  line-height: 24px;
  padding: 20px 36px;
  height: 180px;
  resize: none;
  background: #fafafa;
  border: 1px solid #ececec;
  font-family: 'Open sans', sans-serif;
  font-size: 14px;
  color: #777;
  border-radius: 3px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}


.contact-us .contact-box input:focus,
.contact-us .contact-box textarea:focus,
.contact-us .contact-box select:focus {
  border-color: #ff9a00;
}

.contact-us .contact-box input.error,
.contact-us .contact-box textarea.error,
.contact-us .contact-box select.error {
  border-color: #ff9a00;
}

.contact-us .contact-box input.error:focus,
.contact-us .contact-box textarea.error:focus {
  border-color: #ff9a00;
  box-shadow: 0px 0px 3px 0px #ff9a00;
  -moz-box-shadow: 0px 0px 3px 0px #ff9a00;
  -ms-box-shadow: 0px 0px 3px 0px #ff9a00;
  -o-box-shadow: 0px 0px 3px 0px #ff9a00;
  -webkit-box-shadow: 0px 0px 3px 0px #ff9a00;
}

.contact-us .contact-box label.error {
  display: none !important;
}

.contact-us .contact-box select option {
  padding: 5px 15px;
}

.contact-us .contact-box button {
  background: #ff9a00;
  color: #fff;
  line-height: 45px;
  padding: 0 41px;
}

.contact-us .contact-box button:hover {
  background: #fff;
  color: #ff9a00;
  border-color: #ff9a00;
  transition: .5s ease;
}

.contact-us .contact-box h4.note {
  position: absolute;
  left: 15px;
  top: 5px;
  font-size: 12px;
  color: #777;
  font-weight: 500;
}





/*** 

====================================================================
  about2 style
====================================================================

***/

.about-2 {
  position: relative;
  padding: 120px 0;
}
.about-2 .post-content .section-title h2 {
  position: relative;
}

.about-2 .post-content .section-title h3 {
  margin-bottom: 26px
}


.about-2 .post-content .text {
  overflow: hidden;
  margin-bottom: 15px;
}

.about-2 .post-content .text p {
  color: #777;
  margin-bottom: 14px;
  font-size: 14px;
}


.about-2 .post-content .text a.theme-bg-1 {
  position: relative;
  background: #92278f;
  max-width: 172px;
  padding: 12px 35px;
  border-radius: 1px;
  color: #fff;
  margin: 0 10px;
}
.about-2 .post-content .text a.theme-bg-2 {
  position: relative;
  background: #8ac329;
  max-width: 172px;
  padding: 12px 35px;
  border-radius: 1px;
  color: #fff;
  margin: 0 10px;
}
.about-2 .post-content .text a:hover {
  background: #ff9a00;
  transition: .5s ease-in;
}

.about-2 .img-box {
  position: relative;
  width: 50%;
  float: left;
}
.about-2 .img-box img.fulimg {
  width: 100%;
  padding: 0 2px;
}


.about-2 .img-box .overlay {
  position: absolute;
  top: 0;
  left: 2px;
  right: 2px;
  bottom: 0;  
  background: rgba(27, 28, 29, 0.4);
  text-align: center;
  opacity: 0;
  transition: all .3s ease;
}
.about-2 .img-box:hover .overlay {
  opacity: 1;
}
.about-2 .img-box .overlay .inner {
  display: table;
  width: 100%;
  height: 100%;
  position: relative;
}
.about-2 .img-box .overlay .content {
  display: table-cell;
  vertical-align: middle;
  position: relative;

}
.about-2 .img-box .overlay img {
  position: relative;
  margin-bottom: 10px;
}
.about-2 .img-box .overlay h2 {
  position: relative;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
}


/*** 

====================================================================
  Gallery Sections
====================================================================

***/

.gallery-section{
  position:relative;
  padding:117px 0px 0px;
}
.gallery-section.gallery-three-column{
  position:relative;
  padding:120px 0px 112px;
}

.gallery-section .section-title {
  position:relative;
  padding:0px 0px 57px;
}

.gallery-section .sortable-masonry {
  padding-bottom: 110px;
}
.gallery-section.gallery-three-column .sortable-masonry {
  padding-bottom: 50px;
}

.gallery-section .filters{
  position:relative;
  text-align:center;
  margin-bottom:40px;
}

.gallery-section .filters li{
  position:relative;
  display:inline-block;
  padding:5px 20px;
  line-height:24px;
  color:#777777;
  cursor:pointer;
  text-transform:uppercase;
  font-size:13px;
  font-weight:400;
  margin:0px 5px 10px;
  transition:all 300ms ease;
  -webkit-transition:all 300ms ease;
  -ms-transition:all 300ms ease;
  -o-transition:all 300ms ease;
}

.gallery-section .filters li .txt{
  position:relative;
  display:block;
  z-index:1;  
}

.gallery-section .filters li:before{
  content:'';
  position:absolute;
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  opacity:0;
  background:#71b100;
  transition:all 300ms ease;
  -webkit-transition:all 300ms ease;
  -ms-transition:all 300ms ease;
  -o-transition:all 300ms ease;
  -webkit-transform:skewX(15deg);
  -ms-transform:skewX(15deg);
  -o-transform:skewX(15deg);
  -moz-transform:skewX(15deg);
  transform:skewX(15deg); 
}

.gallery-section .filters li:hover,
.gallery-section .filters li.active{
  color:#ffffff;
}

.gallery-section .filters li:hover:before,
.gallery-section .filters li.active:before{
  opacity:1;
}


.default-portfolio-item{
  position:relative;
}

.gallery-three-column .default-portfolio-item{
  margin-bottom:30px;
}

.fullwidth-gallery{
  overflow:hidden;    
}

.fullwidth-gallery .default-portfolio-item.mix{
  padding:0px 1px;
  margin-bottom:2px;
}

.default-portfolio-item.mix{
  display:none;
}

.gallery-section.fullwidth-gallery .items-container{
  margin-left:-1px;
  margin-right:-1px;  
}

.fullwidth .default-portfolio-item{
  padding:0px !important; 
}

.sortable-masonry .items-container{
  margin-left:-15px;
  margin-right:-15px; 
}

.sortable-masonry .masonry-item{
  padding:0px 5px;
  margin-bottom:10px;
}

.sortable-masonry.style-two .row{
  margin-left:-5px;
  margin-right:-5px;
}


.default-portfolio-item .inner-box{
  position:relative;
  width:100%;
  overflow:hidden;
}

.default-portfolio-item .image-box{
  position:relative;
  display:block;  
}

.default-portfolio-item .image-box img{
  position:relative;
  display:block;
  width:100%;
}

.default-portfolio-item .overlay-box{
  position:absolute;
  left:0px;
  top:-100%;
  width:100%;
  height:100%;
  text-align:center;
  color:#ffffff;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transform:scaleY(0);
  -ms-transform:scaleY(0);
  -o-transform:scaleY(0);
  -moz-transform:scaleY(0);
  transform:scaleY(0);
}

.default-portfolio-item .inner-box:hover .overlay-box{
  top:0px;
  -webkit-transform:scaleY(1);
  -ms-transform:scaleY(1);
  -o-transform:scaleY(1);
  -moz-transform:scaleY(1);
  transform:scaleY(1);    
}

.default-portfolio-item .overlay-inner{
  position:absolute;
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  display:table;
  vertical-align:middle;
  padding:10px 30px;
  background:rgba(38, 62, 75, 0.8);
}

.default-portfolio-item .overlay-inner .content{
  position:relative;
  display:table-cell;
  vertical-align:middle;
}

.default-portfolio-item .overlay-inner h3{
  font-size:18px;
  color:#ffffff;
  font-weight:500;
  text-transform:capitalize;
}

.default-portfolio-item .overlay-inner h3 a{
  color:#ffffff;  
}

.default-portfolio-item .overlay-inner h3 a:hover{
  color:#f1f1f1;
}

.default-portfolio-item .image-link{
  position:absolute;
  left:-100px;
  bottom:0px;
  width:40px;
  height:40px;
  color:#ffffff;
  line-height:40px;
  background:#ff9a00;
  display:block;
  font-size:16px;
  z-index:1;
  text-align:center;
}

.default-portfolio-item:hover .image-link{
  left:0px;
  transition: all 500ms ease 500ms;
  -webkit-transition: all 500ms ease 500ms;
  -ms-transition: all 500ms ease 500ms;
  -o-transition: all 500ms ease 500ms;
  -moz-transition: all 500ms ease 500ms;  
}

/*** 

====================================================================
  Gallery Popup style
====================================================================

***/

body.popup-visible{
  overflow:hidden !important;
}

.gallery-box{
  position:fixed;
  left:0px;
  top:-120%;
  width:100%;
  height:100%;
  overflow:auto;
  padding:0px;
  z-index:9999;
  visibility:hidden;
  opacity:0;
  -webkit-transition: all 1000ms ease-in-out;
  -o-transition: all 1000ms ease-in-out;
  -ms-transition: all 1000ms ease-in-out;
  transition: all 1000ms ease-in-out; 
}


.gallery-box.now-visible{
  top:0px;
  visibility:visible;
  opacity:1;  
}

.gallery-box .bg-fade-layer{
  position:fixed;
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  background:rgba(21,21,21,0.80); 
}

.gallery-box .popup-content{
  overflow:hidden;
  border:1px solid #505050;
}

.gallery-box .popup-header{
  position:relative;
  padding:20px 30px;
  background:#ff9a00;
  color:#ffffff;
}

.gallery-box .popup-header .btn-close{
  position:absolute;
  top:20px;
  right:30px;
  color:#ffffff;
  width:30px;
  height:30px;
  background:rgba(255, 255, 255, 0.21);
  opacity:1;
  font-size:14px;
  line-height:30px;
  padding:0px;
  display:block;
  border:none;
  z-index:1;  
}

.gallery-box .popup-header .close:hover{
  background:#999999; 
}

.gallery-box .popup-header h3{
  position:relative;
  font-size:20px;
  text-align:center;
  line-height:30px;
  color:#ffffff;
  font-weight:500;
  text-transform:capitalize;
}

.gallery-box .popup-container{
  margin:100px auto;
  width:auto;
  max-width:1070px;
  padding:0px 15px;
}

.gallery-box .content-outer{
  position:relative;
  padding:30px 30px;
  background:#ffffff; 
}

.vertical-slider-outer{
  position:relative;
}

.gallery-box .lower-content{
  position:relative;
  padding:20px 0px 0px;   
}

.gallery-box .lower-content .desc-text{
  position:relative;
  margin-bottom:20px; 
}



/*** 
====================================================================
price Section
====================================================================
***/

.pricing-section {
  position: relative;
  text-align: center;
  padding-top: 118px;
  padding-bottom: 117px;
}

.pricing-section.style-2 {
  padding-bottom: 100px;
}

.pricing-section .section-title {
  padding-bottom: 56px;
}

.pricing-column {
  position: relative;
  margin-bottom: 60px;
}



.pricing-column .inner-box {
  position: relative;
  background: #f9f9f9;
  border: 1px solid #ececec;
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
}
.pricing-column:hover .inner-box {
  border-color: #ff9a00;
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
}
.pricing-column .inner-box .gray-bg {
  position: relative;
  border: 9px solid #fff;
  background: #f9f9f9;
  padding-bottom: 42px;
  padding-top: 37px;
}

.pricing-column .plan-header {
  position: relative;
  border-bottom: 4px solid #fff;
  padding-bottom: 20px;

}

.pricing-column .plan-title {
  font-size: 18px;
  padding-bottom: 28px;
  color: #333;
  text-transform: capitalize;
  font-weight: 600;
}

.pricing-column h2 {
  font-size: 50px;
  margin-bottom: 10px;
  color: #333;
  text-transform: capitalize;
  font-weight: 600;
}

.pricing-column .plan-price span.dollar {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
  text-transform: capitalize;
  font-weight: 600;
  position: relative;
  top: -3px;
  left: -12px;
}

.pricing-column .plan-price span.plan-duration {
  font-size: 12px;
  margin-bottom: 10px;
  color: #333;
  position: relative;
}

.pricing-column .plan-stats {
  position: relative;
  padding-top: 37px;
  padding-bottom: 35px;
}

.pricing-column .plan-stats li {
  position: relative;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #777;
  margin-bottom: 8px;
}

.pricing-column .inner-box .price-btn {
  position: relative;
  padding: 12px 53px;
  border-radius: 1px;
  transition: .5s ease-in;
}
.pricing-column:hover .inner-box .price-btn {
  background: #ff9a00;
  transition: .5s ease-in;
  color: #fff;
}
.pricing-section h4 {
  font-size: 15px;
  color: #333;
  text-transform: capitalize;
  font-weight: 500;
}








/*** 

====================================================================
  .video-tour style
====================================================================

***/

.video {
  position: relative;
  background: #343d48;
  padding-top: 110px;
  padding-bottom: 144px;
}
.video .slide-box {
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  min-height: 480px;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
}
.video .section-title {
  position: relative;
}
.video .section-title h2 {
  font-size: 22px;
  color: #fff;
}
.video .section-title h3 {
  color: #fff;
  margin-bottom: 10px;
}

.video .slide-box .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  transition: background .4s ease;
}

.video .slide-box:hover .overlay {
  background: transparent;
}

.video .slide-box .overlay .box {
  display: table;
  width: 100%;
  height: 100%;
}

.video .slide-box .overlay .box .content {
  display: table-cell;
  vertical-align: middle;
}

.video .slide-box .overlay .box .content img {
  margin-left: auto;
  margin-right: auto;
  width: auto;
}

.video .owl-controls {
  text-align: center;
  display: block;
  position: relative;
  margin-top: 10px;
  bottom: -105px;
}


.video .owl-dots .owl-dot {
  display: inline-block;
}
.video .owl-dots .owl-dot span {
  background: none repeat scroll 0 0 #ff9a00;
  border-radius: 50%;
  display: block;
  height: 10px;
  width: 10px;
  margin: 5px 7px;
  transition: opacity 200ms ease 0s;
  border: 2px solid transparent;
}
.video .owl-dots .owl-dot.active span,
.video .owl-dots .owl-dot:hover span {
  background: transparent;
  border-color: #ff9a00;
  height: 12px;
  width: 12px;
}


/*** 

====================================================================
  buy ticket style
====================================================================

***/





.get-direction {
  position: relative;
  padding: 116px 0 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background: #343d48;
}
.get-directione:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  // background:url(/core/fileparse.php/107499/urlt/../images/overly/buy-ticket.png) center center no-repeat;
  background-size: cover;
  z-index: 0;
}

.get-direction .form-area {
  padding: 40px 50px;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.10), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
}


.get-direction .form-area h2 {
  position: relative;
  font-size: 19px;
  font-weight: 600;
  color: #333;
  text-transform: capitalize;
  margin-bottom: 27px;
  margin-top: 4px;
}
.get-direction .form-area h4 {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  text-transform: capitalize;
  margin-bottom: 0px;
  margin-top: 4px;

}

.get-direction .direction-form {
  position: relative;
}




.get-direction .direction-form .btn-style-two {
  border-color: #9e3183;
  background: #9e3183;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0px;
  width: 100%;
  line-height: inherit;
  padding: 13px;
  margin-bottom: 16px;
}
.get-direction .direction-form .btn-style-two:hover {
  color: #9e3183;
  background: #fff;
  transition: .5s ease;
}


.get-direction .direction-form input[type="text"],
.get-direction .direction-form input[type="email"],
.get-direction .direction-form input[type="password"],
.get-direction .direction-form select{
  position:relative;
  width:100%;
  line-height:24px;
  font-size: 13px;
  font-family: 'Lora', serif;
  font-style: italic;
  color:#777;
  background:#fff;
  border: 1px solid #eee;
  height: 50px;
  padding: 0px 20px;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

.get-direction .direction-form textarea{
  position:relative;
  width:100%;
  line-height:24px;
  padding: 10px 20px 0;
  height: 190px;
  resize:none;
  font-family: 'Lora', serif;
  font-style: italic;
  background:#fff;
  border: 1px solid #eee;
  font-size: 13px;
  color: #777;
  padding-bottom:0px;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}


.get-direction .direction-form input:focus,
.get-direction .direction-form textarea:focus,
.get-direction .direction-form select:focus{
  border-color:#ff9a00;   
}

.get-direction .direction-form input.error,
.get-direction .direction-form textarea.error,
.get-direction .direction-form select.error{
  border-color:#ff9a00;
}

.get-direction .direction-form input.error:focus,
.get-direction .direction-form textarea.error:focus{
  border-color:#ff9a00;
  box-shadow:0px 0px 3px 0px #ff0000;
  -moz-box-shadow:0px 0px 3px 0px #ff9a00;
  -ms-box-shadow:0px 0px 3px 0px #ff9a00;
  -o-box-shadow:0px 0px 3px 0px #ff9a00;
  -webkit-box-shadow:0px 0px 3px 0px #ff9a00;
}
.get-direction .direction-form label.error{
  display:none !important;
}



.get-direction .direction-form .field-label {
  display: block;
  text-transform: capitalize;
  font-family: 'Raleway',sans-serif;
  color: #777;
  font-size: 14px;
  margin-bottom: 9px;
}
.get-direction .subscribe-check {
  position: relative;
  margin-right: 20px;
}
.get-direction .subscribe-check .radio {
  position: relative;
  color: #777;
  font-size: 13px;
  font-family: 'Raleway', sans-serif;
  margin-top: 25px;
  margin-bottom: 18px;
}

.get-direction .subscribe-check .check-boxe input[type="radioe"] {
  position: relative;
  top: 2px;
  margin: 0;
  margin-right: 11px;
}

.get-direction .direction-info {
  position: relative;
  margin-bottom: 80px;
}
.get-direction .direction-info .section-title {
  margin-bottom: 13px;
}
.get-direction .direction-info .section-title h2 {
  font-size: 32px;
  color: #fff;
}
.get-direction .direction-info .section-title h3 {
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 9px;
  color: #fff;
}

.get-direction .direction-info .venue-details .item {
  position: relative;
  overflow: hidden;
  margin-bottom: 18px;
  padding-bottom: 14px;
}

.get-direction .direction-info .venue-details .item:after {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0;
  width: 63%;
  height: 1px;
  background: rgba(236, 236, 236, 0.05);
}

.get-direction .direction-info .venue-details .addr-info {
  margin-right: 62px;
}

.get-direction .direction-info .venue-details h2 {
  position: relative;
  font-size: 18px;
  color: #ff9a00;
  font-weight: 600;
  margin-bottom: 25px;
}

.get-direction .direction-info .venue-details h4 {
  position: relative;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 7px;
}
.get-direction .direction-info .venue-details .item p {
  position: relative;
  font-size: 14px;
  color: #bbb;
  font-family: "Poppins", sans-serif;
}
.get-direction .direction-info .text {
  margin-top: 5px;
}
.get-direction .direction-info .text p {
  position: relative;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
}

.get-direction .direction-info .btn-style-one {
  position: relative;
  padding: 12px 42px;
  color: #fff;
  background: #ff9a00;
}
.get-direction .direction-info .btn-style-one:hover {
  border: 1px solid #ff9a00;
  color: #ff9a00;
  background: #fff;
  transition: .5s ease-in;
}



/***
====================================================================
schedule style
====================================================================
***/

.event-schedule {
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
  background: #f9f9f9;
}
/*===========style-2===============*/
.style-2.event-schedule {
  background: #fff;
  padding-bottom: 0px;
  padding-top: 75px;
}
.style-2.event-schedule .section-title {
  margin-bottom: 35px;
}

.style-2.event-schedule .inner-box {
  background: #f8f8f8;
}
.style-2.event-schedule .table>thead>tr>th.h-time {
  background: #fff;
}
.style-2.event-schedule .table>thead>tr>th.h-speaker {
  background: #fcfcfc;
  padding-right: 50px;
}
.style-2.event-schedule .table>thead>tr>.h-venue {
  background: #fff;
}
.style-2.event-schedule .table>tbody>tr>td {
  background: #f8f8f8;
}

/*========end===========*/



.event-schedule .section-title {
  margin-bottom: 42px;
}


.event-schedule .inner-box {
  border: 1px solid #eee;
  border-radius: 5px;
  overflow: hidden;
}
.event-schedule .inner-box .table {
  margin-bottom: 0px;
}

.event-schedule .table>thead>tr>th {
  border-bottom: none;
  padding: 23px 8px 24px;
  font-weight: 500;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #777;
  text-transform: capitalize;
}
.event-schedule .table>thead>tr>th.h-time {
  background: #f8f8f8;
}
.event-schedule .table>thead>tr>th.h-speaker {
  background: #f3f3f3;
}
.event-schedule .table>thead>tr>.h-venue {
  background: #f8f8f8;
}


.event-schedule .table-hover>tbody>tr:hover {
  transition: .3s;
}

.event-schedule .table-hover>tbody>tr {
  background-color: #fff;
  transition: .3s;
  text-transform: capitalize;
}


.event-schedule .table>tbody>tr {
  border-top: 1px solid #efefef;
}
.event-schedule .table>tbody>tr:nth-child(1) {
  border-top: 0;
}

.event-schedule .table>tbody>tr>td {
  padding: 23px 0px;
  color: #333;
  font-size: 14px;
  border: 0;
  font-family: 'Poppins', sans-serif;
  vertical-align: middle;
  font-weight: 500;
}


.event-schedule .table>tbody>tr>td.session-time {
  padding-left: 108px;
}
.event-schedule .table>tbody>tr>td.session-time .time {
  color: #ff9a00;
  font-weight: 600; 
}

.event-schedule .table>tbody>tr>td.team {
  padding-left: 75px;
  padding-right: 70px;
  
}
.event-schedule .table>tbody>tr>td.venue {
  text-align: center;
  padding: 0 80px;
}

.event-schedule .table>tbody>tr>td .speaker a {
  padding: 23px 0px;
  color: #333;
  font-size: 14px;
  border: 0;
  font-family: 'Poppins', sans-serif;
  vertical-align: middle;
  font-weight: 500;
}
.event-schedule .table>tbody>tr>td .session a:hover {
  color: #333;
}

.event-schedule .table>tbody>tr td .speaker {
  position: relative;
  padding-left: 65px;
}
.event-schedule .table>tbody>tr td .speaker .image-box {
  position: absolute;
  left: 0;
  top: -14px;
}



/*tab*/



#schedule-tab {
  position: relative;
}


.event-schedule .schedule-tab-title .table>thead>tr>th {
  background: #fff;
  cursor: pointer;
  padding: 20px 0;
  text-align: center;
  border-right: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
}

.event-schedule .schedule-tab-title .table>tbody>tr>td {
  background: #f9f9f9;
  cursor: pointer;
  padding: 20px 0 21px;
  text-align: center;
  border-right: 1px solid #fff;
}
.event-schedule .schedule-tab-title .table>tbody>tr>td:last-child {
  border-right: 0px;
}

.event-schedule .schedule-tab-title .table>tbody>tr>td h2 {
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
  font-weight: 600;
  text-transform: capitalize;
}
.event-schedule .schedule-tab-title .table>tbody>tr>td h2 span {
  font-size: 10px;
  position: relative;
  top: -4px;
}


.event-schedule .schedule-tab-title .table {
  margin-bottom: 0px;
}
.event-schedule .inner-box {
  border: none;
  border-radius: 0px;
  background: #fff;
  padding: 10px 10px 0;
}

.event-schedule .schedule-tab-title .table>tbody>tr>td:hover,
.event-schedule .schedule-tab-title .table>tbody>tr>td.active {
  background: #ff9a00;
}
.event-schedule .schedule-tab-title .table>tbody>tr>td:hover h2,
.event-schedule .schedule-tab-title .table>tbody>tr>td.active h2 {
  color: #fff;
}




/*** 

====================================================================
  Testimonials Section style
====================================================================

***/

.testimonials-section{
  position:relative;
  padding:120px 0px 80px;
  background-position:center top;
  background-size:cover;
  background-attachment:fixed;
  background-repeat:no-repeat;
}


.testimonials-section .sec-title {
  border:none;
  color:#ffffff;
}

.testimonials-section .sec-title h2{
  color:#ffffff;  
}

.testimonials-section .sec-title .text{
  color:#ffffff;  
}

.testimonials-section .testimonials-slider{
  position:relative;
}

.testimonials-section .testimonials-slider .slide-item{
  position:relative;
  margin:70px 0px 0px 0px;
  padding: 50px 32px 56px;
  background:#ffffff;
  border: 1px solid #ececec;
}


.testimonials-section .quote {
  position: absolute;
  font-size: 300px;
  font-family: "Vani";
  right: 20px;
  top: -40px;
  color: #777;
  opacity: 0.05;
}
.testimonials-section .author{
  position:relative;
  padding:15px 0px 0px 80px;
  margin-bottom:28px;
  color:#c9c9c9;
  line-height:20px;
}

.testimonials-section .author .img-box {
  position:absolute;
  left:0px;
  top:0px;
}
.testimonials-section .author .img-box img {
  border-radius: 50%;
}


.testimonials-section .author h4 {
}

.testimonials-section .author h4 a {
  position:relative;
  color:#333;
  font-size: 14px;
  font-weight: 500;
}
.testimonials-section .author h4 a:hover {
  color: #333;
}

.testimonials-section .author p {
  position:relative;
  color:#777;
  font-size:14px;
  font-family: 'Lora', serif;
  font-style: italic;
}

.testimonials-section .slide-text{
  position:relative;
  font-size:14px;
  color:#777;  
}

.testimonials-section .column-carousel.three-column .owl-nav{
  display:none !important;    
}

.testimonials-section .column-carousel.three-column .owl-controls{
  position:relative;
  text-align:center;
  padding:20px 0px;
  top:0px;    
}

.testimonials-section .column-carousel.three-column .owl-dots{
  display:block !important;
}

.testimonials-section .column-carousel.three-column .owl-dot span{
  background:none !important;
  border:2px solid #ffffff;
  
}

.testimonials-section .column-carousel.three-column .owl-dot span:hover,
.testimonials-section .column-carousel.three-column .owl-dot.active span{
  border-color:#4bc700;
  
}

.testimonials-section.theme-two .column-carousel.three-column .owl-dot span{
  border-color:#4bc700;
  
}




/*** 

====================================================================
  footer style
====================================================================

***/


.footer {
  position: relative;
  padding: 65px 0 55px;
}
.footer:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  /*
  background:url(/core/fileparse.php/107499/urlt/../images/overly/1.jpg) center center no-repeat;
  background-size: cover;
  */
  z-index: 0;
}

.footer .pull-left {
  margin-top: 15px;
}

.footer .menu {
  position: relative;
  /*border-bottom: 1px solid#4a535c;*/
}

.footer .menu ul {
  position: relative;
}

.footer .menu ul li {
  position: relative;
  float: left;
  margin-left: 36px;
}
.footer .menu ul li:first-child {
  margin-left: 0px;
}

.footer .menu ul li a {
  position: relative;
  line-height: 69px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: .5s ease;

}

.footer .menu ul li a:hover {
  color: #ff9a00;
  transition: .5s ease;
}


.footer .copy-right {
  float: right;
  position: relative;
  line-height: 59px;
  font-size: 15px;
  color: #fff;
  font-family: 'Poppins', sans-serif;

}


.footer .copy-right span {
  color: #ff9a00;

}


/*style-2*/
.footer.style-2 {
  position: relative;
  padding: 80px 0 0px;
  // background-image: url(/core/fileparse.php/107499/urlt/../images/background/1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.footer.style-2 img {
  margin-bottom: 19px;
}

.footer.style-2 .menu {
  position: relative;
  border-bottom: 0px;
  margin-bottom: 46px;
}
.footer .info h4 {
  position: relative;
  font-size: 14px;
  color: #bbb;
  margin-bottom: 16px;
}

.footer.style-2 .copy-right {
  float: none;
  background: rgba(0, 0, 0, 0.1);

}
.footer.style-2 .copy-right span {
  color: #fff;

}



/*pagination*/





.pagination {
  position:relative;
  top: 0px;
  border:none;
  margin: 0;
  border-radius: 0;
}
.sidebar-page .pagination {
  padding-top: 20px;
}

.pagination.center-position {
  text-align: center;
  width: 100%;
}

.pagination li{
  margin: 0 -2px;
  display:inline-block;
  border-radius:0px;
  border:1px solid #ececec;
  -moz-border-radius:0px;
  -webkit-border-radius:0px;
  -ms-border-radius:0px;
}
.pagination li:hover{
  border-color: #ff9a00;
  border-radius: 0px !important;
  transition: .5s ease;
}

.pagination li a{
  position: relative;
  display: block;
  font-weight: 500;
  padding: 16px 23px;
  font-size: 13px;
  color: #333;
  border: none;
  transition: .5s ease;
}

.pagination li.current a{
  color: #ff9a00;
}
.pagination li.current a:hover,
.pagination li:hover a{
  color: #fff;
  background: #ff9a00;
  border-radius: 0px !important;
  transition: .5s ease;
}




/*** 

====================================================================
  payment-method style
====================================================================

***/


.payment-method {
  position: relative;
  padding: 120px 0;
}
.payment-method h2 {
  margin-bottom: 40px;
}

.payment-method .payment-check .radio {
  position: relative;
  padding: 24px 0;
  color: #333;
  font-size: 16px;
  background: #f9f9f9;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin: 0;
  padding-left: 50px;
  margin-bottom: 20px;
}

.payment-method .payment-check .radio input {
  margin-left: -25px;
}


.payment-method .payment-check .radio label {
  font-weight: 600;
}


.payment-method .payment-btn {
  padding-top: 30px;
}

.payment-method .payment-btn .btn-style-one.bg-1{
  background: #92278f;
  color: #fff;
  padding: 12px 20px;
  margin-right: 16px;
}

.payment-method .payment-btn .btn-style-one.bg-2{
  background: #8ac329;
  color: #fff;
  padding: 12px 20px;
}

.payment-method .payment-btn .btn-style-one:hover {
  background: #ff9a00;
  transition: .5s ease-in;
}


/*========= speaker-details =============*/


.speaker-details {
  position: relative;
  padding: 120px 0;
}
.speaker-details .img-box img {
  position: relative;
  width: 100%;
}

.speaker-details .post-content .section-title {
  margin-bottom: 30px;
}

.speaker-details .post-content .section-title h2 {
  font-size: 20px;
  margin-bottom: 4px;
  margin-top: 25px;
}

.speaker-details .post-content .section-title h3 {
  font-size: 16px;
  margin-top: 0px;
}


.speaker-details .post-content .text {
  overflow: hidden;
  margin-bottom: 15px;
}

.speaker-details .post-content .text p {
  font-size: 15px;
}

.speaker-details .list-info {
  position: relative;
}
.speaker-details .list-info ul li {
  font-size: 15px;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
}
.speaker-details .list-info ul li span {
  font-size: 14px;
  font-weight: 400;
}

.speaker-details .list-info ul li a {
  font-size: 13px;
  color: #ff9a00;
  margin-right: 10px;
}




/*** 

====================================================================
  Blog Page Style
====================================================================

***/


.blog-container.blog-section {
  padding: 0 15px;
}
.blog-container.blog-section .blog-post .post-info.lower {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
  color: #707070;
}
.blog-container.blog-section .blog-post .post-info.lower .read-more {
  border: none;
}

.sidebar-page{
  position:relative;
  padding:100px 0px 120px;  
}

.blog-container{
  position:relative;
  padding:0px 0px;
}

.blog-container .blog-post{
  position:relative;
  margin-bottom:60px;
  background:none;    
}

.blog-container .section-title {
  padding-top: 33px;
  padding-bottom: 82px;
}

.blog-container .section-title h2 {
  font-size: 36px;
  color: #2b3033;
  margin-bottom: 30px;
}

.blog-container .section-title h2 a {
  color: #2b3033;
  transition: .5s ease;
}

.blog-container .section-title h2 a:hover {
  color: #08c1d7;
  transition: .5s ease;
}


.blog-container .section-title p {
  position: relative;
  font-size: 18px;
}



.blog-container .img-box {
  position: relative;
  overflow: hidden;
}

.blog-container .img-box img {
  position: relative;
  width: 100%;
  display: block;
  transition: .5s ease;
}


.blog-container .content {
  position: relative;
  background: #fff;
  padding: 35px 40px 48px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(0, 0, 0, 0.05);

}
.blog-container .content h2 {
  position: relative;
  font-size: 16px;
  color: #333;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 12px;
}
.blog-container .content h2 a {
  color: #333;
  transition: .5s ease;
}
.blog-container .content h2 a:hover {
  color: #ff9a00;
  transition: .5s ease;
}

.blog-container .content h4 {
  position: relative;
  font-size: 12px;
  color: #777;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.blog-container .content h4 .separet {
  position: relative;
  padding: 0 7px;
}
.blog-container .content h4 .separet:after {
  position:absolute;
  content: "";
  left:5px;
  top:0px;
  bottom: 0;
  width:1px;
  height:100%;
  background: #ff9a00;
}

.blog-container .content p {
  position: relative;
  font-size: 14px;
  color: #777;
  line-height: 24px;
  margin-bottom: 13px;
}

.blog-container .content a.btn-style-default {
  position: relative;
  font-size: 13px;
  color: #ff9a00;
  transition: .5s ease;
}

.blog-container .content a.btn-style-default span:before {
  font-size: 18px;
  position: relative;
  left: 6px;
  top: 2px;
}








/*=================*/


.single-blog-post {
  position: relative;
  padding: 100px 0 27px;
}

.single-blog-post .quote {
  position: relative;
  padding-left: 295px;
  padding-bottom: 10px;
}
.single-blog-post .quote .img-box2 {
  position: absolute;
  left: 0;
  top: 0;

}
.single-blog-post .quote .img-box2 img {
  width: 100%;

}

.single-blog-post .text {
  position: relative;
  padding-left: 30px;
}

.single-blog-post .blog-container .content p {
  line-height: 28px;
}

.single-blog-post .post-navigation {
  position: relative;
  padding-top: 27px;
}
.single-blog-post .post-navigation a {
  font-size: 14px;
  color: #777;
  font-weight: 500;
  padding-left: 8px;
  transition: .5s ease-in;
}

.single-blog-post .post-navigation a:hover {
  color: #ff9a00;
  transition: .5s ease-in;
}

.single-blog-post .post-navigation a.prev span {
  padding-right: 10px;
}
.single-blog-post .post-navigation a.next span {
  padding-left: 10px;
}

.single-blog-post .post-navigation span:before {
  font-size: 17px;
  position: relative;
  top: 3px;
}
.single-blog-post .single-column {
  margin-bottom: 66px;
}


/*==========coment==========*/



.single-blog-post .comment-box {
margin-bottom: 80px;
}
.single-blog-post .comment-box .title,
.single-blog-post .leave-comment .title {
margin-bottom: 30px;
}
.single-blog-post .comment-box .title h2,
.single-blog-post .leave-comment .title h2 {
color: #333;
font-weight: 600;
font-size: 18px;
margin: 0;
}
.single-blog-post .single-comment-box {
padding: 35px 0;
padding-bottom: 0px; 
}
.single-blog-post .single-comment-box:last-child {
margin-bottom: 0;
}
.single-blog-post .single-comment-box.reply-comment {
margin-left: 135px;
}
.single-blog-post .single-comment-box .img-box2,
.single-blog-post .single-comment-box .content-box {
display: table-cell;
vertical-align: top;
}
.single-blog-post .single-comment-box .img-box2 {
width: 112px;
padding-right: 25px;
}
.single-blog-post .single-comment-box .img-box2 img {
  border-radius: 50%;
}
.single-blog-post .single-comment-box .content-box {
border-bottom: 1px solid #eee;
padding-bottom: 15px;
}
.single-blog-post .single-comment-box .content-box h4 {
margin: 0;
color: #333;
text-transform: capitalize;
font-size: 13px;
margin-top: 11px;
}
.single-blog-post .single-comment-box .content-box span {
font-size: 12px;
color: #777;
font-weight: 400;
font-family: "Poppins", sans-serif;
margin-top: 5px;
}
.single-blog-post .single-comment-box .content-box p {
line-height: 25px;
margin: 0;
margin-top: 13px;
margin-bottom: 5px;
}
.single-blog-post .single-comment-box .content-box .reply-button {
font-size: 12px;
display: inline-block;
text-transform: capitalize;
color: #333;
line-height: 33px;
transition: all .3s ease;
}
.single-blog-post .single-comment-box .content-box .reply-button:hover {
color: #ff9a00;
}

.single-blog-post .contact-form {
  padding-top: 20px;
}

.single-blog-post .contact-form .form-group input,
.single-blog-post .contact-form .form-group input[type="text"],
.single-blog-post .contact-form .form-group input[type="email"],
.single-blog-post .contact-form .form-group input[type="url"],
.single-blog-post .contact-form .form-group input[type="password"],
.single-blog-post .contact-form .form-group select,
.single-blog-post .contact-form .form-group textarea {
  border-bottom: 2px solid #ebebeb;
  background: #fff;
  border-radius: 0px;
  padding: 7px 15px 7px;
  padding-left: 0;
  width: 100%;
  resize: none;
  color: #333;
  font-size: 12px;
}

.single-blog-post .contact-form .form-group input:focus,
.single-blog-post .contact-form .form-group textarea:focus,
.single-blog-post .contact-form .form-group select:focus{
  border-color: #333;   
}

.single-blog-post .contact-form .form-group input.error,
.single-blog-post .contact-form .form-group textarea.error,
.single-blog-post .contact-form .form-group select.error{
  border-color: #ff9a00;
}


.single-blog-post .contact-form .form-group label.error{
  display:none !important;
}



.single-blog-post .contact-form .form-group {
  margin-bottom: 33px;
}

.single-blog-post .contact-form .form-group textarea {
  height: 135px;
}


.single-blog-post .contact-form button {
  background: #ff9a00;
  color: #fff;
  line-height: 53px;
  padding: 0 32px;
  border: 1px solid #ff9a00;
}
.single-blog-post .contact-form button:hover {
  background: #fff;
  color: #ff9a00;
  transition: .5s ease-in;
}







/*** 

====================================================================
  Side Bar Style
====================================================================

***/
.sidebar-page .sidebar{
  position:relative;
  padding:0px 0px 0px;    
}

.sidebar .widget{
  position:relative;
  margin-bottom:33px;
}

.sidebar .search-form input[type="search"],
.sidebar .search-form input[type="text"]{
  position:relative;
  width:100%;
  line-height:24px;
  padding:5px 50px 5px 15px;
  border:1px solid #efefef;
  background:#ffffff;
  color:#777;
  height:45px;
  font-size:13px;
  font-family: "Raleway, sans-serif";
  color:#292929;
  border-radius:0px;
}

.sidebar .search-form input[type="search"]:focus,
.sidebar .search-form input[type="text"]:focus{
  border-color:#ff9a00;   
}

.sidebar .search-form .form-group{
  position:relative;  
}

.sidebar .search-form button{
  position: absolute;
  right: 1px;
  top: 1px;
  line-height: 24px;
  display: block;
  width: 50px;
  height: 43px;
  padding: 7px 10px 8px;
  text-align: center;
  font-size: 20px;
  color: #777;
  background: #fff;
  border-radius: 0px 3px 3px 0px;
  -webkit-border-radius: 0px 3px 3px 0px;
  -ms-border-radius: 0px 3px 3px 0px;
  -o-border-radius: 0px 3px 3px 0px;
  -moz-border-radius: 0px 3px 3px 0px;
  transition: .5s ease;
}
.sidebar .search-form button span:before {
  font-size: 15px;
}

.sidebar .search-form button:hover{
  color: #ff9a00;
  transition: .5s ease;
}

.sidebar .sidebar-title{
  position:relative;
  margin-bottom:30px;
  padding:10px 0px;
}

.sidebar .sidebar-title h2{
  font-size:17px;
  color:#333;
  font-weight: 500;
  text-transform:capitalize;  
}

.sidebar .sidebar-title:after {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 30px;
  height: 2px;
  background: #ff9a00;
}

.sidebar .recent-posts .post{
  position: relative;
  font-size: 14px;
  margin-bottom: 15px;
  min-height: 60px;
  padding: 0px 0px 24px 100px;
  color: #cccccc;
  border-bottom: 1px solid #eaeaea;
}
.sidebar .recent-posts .post:last-child{
  border-bottom: 0px;
}

.sidebar .recent-posts .post .post-thumb{
  position:absolute;
  left:0px;
  top:0px;
  width:81px;
  height:82px;    
}

.sidebar .recent-posts .post .post-thumb img{
  width:100%;
  display:block;  
}

.sidebar .recent-posts .post h4{
  position: relative;
  top: 8px;
  font-size:14px;
  font-weight:500;
  margin:0px 0px 7px;
  line-height:20px;
  color:#333;  
}
.sidebar .recent-posts .post .post-info{
  font-size:15px;
  font-weight:400;
  margin:0px;
  line-height:1.6em;
  color:#777;
  font-family:'Poppins',sans-serif; 
}

.sidebar .recent-posts .post a,
.sidebar .recent-posts .post a:hover{
  color:#ff9a00;
  transition: .5s ease;  
}

.sidebar .recent-posts .post h4 a{
  color:#333; 
  transition: .5s ease; 

}

.sidebar .recent-posts .post .fa{
  font-size:18px; 
}

.sidebar-page .contact-info{
  position:relative;
  font-size:14px;
  margin-bottom:30px;
}

.sidebar-page .contact-info .text{
  position:relative;
  font-size:13px;
  font-family:'Open Sans',sans-serif;
  margin-bottom:10px;
  color:#7f7f7f;
}

.sidebar-page .contact-info li{
  position:relative;
  margin-bottom:5px;
  line-height:1.8em;
  color:#7f7f7f;  
}

.sidebar-page .contact-info li strong{
  position:relative;
  padding-right:10px;
  font-weight:600;
  color:#292929;  
}

.sidebar-page .contact-info a{
  color:#f17338;
  transition:all 300ms ease-in;
  -webkit-transition:all 300ms ease-in;
  -ms-transition:all 300ms ease-in;
  -o-transition:all 300ms ease-in;
  -moz-transition:all 300ms ease-in;  
}

.sidebar-page .contact-info a:hover{
  color:#3d3d3d;  
}

.sidebar .popular-tags a {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 5px 17px;
  background: none;
  color: #999999;
  margin: 0px 7px 11px 0px;
  text-transform: capitalize;
  font-size: 12px;
  border: 1px solid #f6f6f6;
  border-left-width: 2px;
  border-right-width: 2px;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.sidebar .popular-tags a:hover{
  border-color:#ff9a00; 
  color: #333;
}

.sidebar .list{
  position:relative;
}

.sidebar .list li{
  position:relative;
  line-height:32px;
  padding:7px 0px 3px 0px;
  border-bottom:1px solid #f2f2f2;    
}

.sidebar .catagories.list li:after{
  position:absolute;
  content: "";
  left:6px;
  top:19px;
  width:5px;
  height:5px;
  line-height:32px;
  display:block;
  background:#ff9a00;
}

.sidebar .catagories.list li a{
  padding-left: 22px;
}

.sidebar .list li a{
  position:relative;
  display:block;
  color:#333;
  font-size:13px;
  font-weight:300;
}

.sidebar .list li a:hover{
  color:#ff9a00;  
}

.sidebar .recent-gallery{
  position:relative;
}

.sidebar .recent-gallery .image{
  position:relative;
  float:left;
  width:33.333%;
  padding:0px 10px 10px 0px;  
}

.sidebar .recent-gallery .image img{
  position:relative;
  display:block;
  width:100%; 
}








/***
====================================================================
schedule details style
====================================================================
***/

.schedule-details {
  position: relative;
  background: #f6f6f6;
  padding-top: 100px;
}

.schedule-details .sidebar-page {
  position: relative;
  margin-bottom: 100px;
  padding: 0px;
}

.schedule-details .sidebar-page .content {
  position: relative;
  background: #fff;
  margin-bottom: 30px;
}

.schedule-details .sidebar-page .content ul li {
  position: relative;
  text-align: right;
  padding-left: 44px;
  padding-right: 34px;
  border-bottom: 1px solid #eee;
}

.schedule-details .sidebar-page .content ul li:last-child {
  border-bottom: 0px solid #eee;
}

.schedule-details .sidebar-page .content ul li a {
  line-height: 80px;
  font-size: 13px;
  font-weight: 400;
  color: #999;
  transition: all .3s ease;
}

.schedule-details .sidebar-page .content ul li a:hover {
  color: #121212;
}

.schedule-details .sidebar-page .content ul li a span {
  position: absolute;
  left: 44px;
  line-height: 80px;
  font-size: 21px;
}

.schedule-details .sidebar-page .image {
  position: relative;
}

.schedule-details .sidebar-page .image img {
  position: relative;
  width: 100%;
}

.schedule-details .sidebar-page .image:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 5px solid #fff;
}

.schedule-details .single-post {
  position: relative;
  padding-left: 30px;
}

.schedule-details .single-post .image {
  position: relative;
}

.schedule-details .single-post .image img {
  position: relative;
  width: 100%;
}

.schedule-details .single-post .content {
  position: relative;
}

.schedule-details .single-post .content h2 {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  text-transform: capitalize;
  margin: 48px 0 22px;
}

.schedule-details .single-post .content p {
  position: relative;
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 29px;
  margin-right: 5px;
}

.schedule-details .single-post .content .button {
  padding-top: 18px;
  padding-bottom: 105px;
}

.schedule-details .single-post .content .button a {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 14px 45px;
  border: 2px solid #ff9a00;
  color: #fff;
  background: #ff9a00;
  line-height: 48px;
  transition: .5s ease;
}
.schedule-details .single-post .content .button a:hover {
  background: transparent;
  color: #ff9a00;
  transition: .5s ease;
}

.btn-purp {
background: #63619a;
  color: #fff !important;
  border-radius: 0;
}
.btn-purp:hover {
background: #000;
}
@media screen and (min-width: 600px) {
.mobile-content-thanks {
display: none !important;
}
}