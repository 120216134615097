body {
  font-family: $standard-family;
  line-height: 1.5;
  font-size: $font-size;
  font-weight: 400;
  overflow-x: hidden;
  color: var(--bs-boulder);
}
p {
  line-height: 1.7;
}
[id] {
  scroll-margin-top: 4rem;
}
a {
  &:not(.btn) {
    &:hover {
      color: var(--bs-celery-green)!important;
    }
  }
}
.btn {
  padding: 12px 27px;
  font-size: $font-size - .125rem;
  margin: 10px 0;
  border-radius: 0;
  transition: all ease-in-out .5s;
  font-family: $standard-family;
  &-celery-green {
    color: var(--bs-white);
    &:hover {
      color: var(--bs-white);
    }
  }
}
.scroll-to-top {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 45px;
  height: 45px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--bs-white);
  border-radius: 50%;
  margin: 0;
  text-align: center;
  cursor: pointer;
  display: block;
  opacity: .8;
  transition: .3s ease-in;
  .fas {
    &:before {
      color: var(--bs-orange);
      font-size: 20px;
      margin: 0;
      line-height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
  &:hover {
    opacity: 1;
    transition: .3s ease-in;
    box-shadow: 0 5px 11px 0 rgba(var(--bs-black), 0.18), 0 4px 15px 0 rgba(var(--bs-black), 0.15);
  }
}
.bubble {
  width: 280px;
  height: 280px;
  font-size: $font-size + .25rem;
  cursor: pointer;
  @include media-breakpoint-only(xl) {
    width: 330px;
    height: 330px;
    font-size: $font-size + .5rem;
  }
  @include media-breakpoint-only(lg) {
    width: 260px;
    height: 260px;
  }
  @include media-breakpoint-only(md) {
    width: 220px;
    height: 220px;
  }
  @include media-breakpoint-down(md) {
    margin: 2rem auto;
  }
  &:hover {
    transform: scale(1.1, 1.1);
    transition: .5s ease;
  }
}
.filter-invert {
  filter: invert(1);
}
.stamp {
  top: 23%;
  right: 0;
  left: 0;
  margin: 0 auto;
}