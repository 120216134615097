/* DigitalUs Override */

/* =========================
# General
========================= */
@media only screen and (min-width: 767px) {
  .w-sm-100p {
    width: 100px;
  }
  .w-sm-80p {
    width: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .main-header img {
    width: 100px;
  }
}
.green-text {
  color: #b0c14d;
}
.w-200p {
  width: 200px;
}
[id] {
  scroll-margin-top: 80px;
}
/* =========================
# Header
========================= */
.hero .h1 {
  line-height: 33px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  color: #3b6b6e;
  font-family: 'Montserrat', sans-serif;
}
/* =========================
# Buy Ticket Section
========================= */
.buy-ticket .ticket-info .item .right-column {
  padding: 10px 0 20px 35px;
}
.buy-ticket .ticket-info .item .list li svg {
  color: #b0c14d;
}
/* =========================
# Sponsors Section
========================= */
.sponser-section {
  margin-top: 40px;
}
.sponser-section .owl-carousel .owl-item {
  padding: 52px 0;
}
.sponser-section .owl-carousel .owl-item img {
  max-width: 200px;
  width: 90%;
  height: auto;
}
.seal-logo {
  width: 100%;
  max-width: 185px;
}