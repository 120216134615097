@import 'utilities/variables';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&family=Montserrat:wght@200;400;700&family=Raleway:wght@400;700&display=swap');

@import 'animate.css';

@import '~bootstrap/scss/bootstrap';
@import '~slick-carousel/slick/slick.scss';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import 'utilities/utilities';
@import 'utilities/typography';

@import 'base/main';

@import 'layout/nav';

@import 'components/style';
@import 'components/style-du';
@import 'components/style-du-2018';
@import 'components/slider';
@import 'components/event-tab';
@import 'components/map';
@import 'components/hero';
@import 'components/sponsorship';
@import 'components/venue';

@import 'components/tampa';