h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  font-weight: 700;
  font-family: $headings-family;
  color: var(--bs-mine);
}
.header-slider,
.hero {
  h2 {
    color: var(--bs-spectra);
    font-family: $headings-family;
    @include media-breakpoint-up(lg) {
      font-size: $font-size + 1.25rem;
    }
  }
  h3 {
    font-weight: 200;
    font-family: $helvetica-family;
    @include media-breakpoint-up(lg) {
      font-size: $font-size + .875rem;
    }
    @include media-breakpoint-only(md) {
      font-size: $font-size + .5rem;
    }
  }
}
h1 {
  font-weight: 200;
  color: var(--bs-celery-green);
  font-size: calc(2.5rem + 1.5vw);
  @include media-breakpoint-up(xl) {
    font-size: $font-size + 4.3rem;
  }
  @include media-breakpoint-between(sm, xl) {
    font-size: $font-size + 3.9rem;
  }
}
h2 {
  font-family: $standard-family;
  ~ p {
    margin-top: 1.5rem;
  }
}
h3 {
  @include media-breakpoint-up(md) {
    font-size: $font-size + .6rem;
  }
}
.standard-font {
  font-family: $standard-family;
}
.heading-font {
  font-family: $headings-family;
}
.fs {
  &-20p {
    @include media-breakpoint-up(md) {
      font-size: $font-size + .25rem;
    }
  }
}